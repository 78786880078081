<script setup lang="ts">
import { onMounted } from 'vue'
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'Sanctuary Layout',
})

const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const hideWidgetOnPageIds: Array<number> = [580]
const showBookingWidget: boolean = !hideWidgetOnPageIds.includes(currentPage.id)

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022')

const {
  resorts,
  brands,
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, ['footerCompanyMenu', 'footerResourcesMenu'])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#024263' })
provide('currentResort', currentResort.value.data)
provide('resorts', resorts.value.data)
provide('brands', brands.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white sanctuary-theme"
    >
      <div id="app">
        <div id="portal-destination" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              menuPosition: 'right', // right or center
              logoPosition: 'left',
              backgroundClass: 'bg-white',
              paper: false,
              bookNowBackground: 'bg-[#024263]',
              allResorts: true,
              booking: showBookingWidget,
            }"
          />
          <slot />

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :config="{
              crossPromotion: false,
              signUpForm: true,
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/sanctuary.scss';
</style>
